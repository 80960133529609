<template>
  <div>
    <AddNewPayment
      v-model="isAddNewPayment"
      @onAdd="fetchDataTable"
    />

    <EditPayment
      v-model="isEditPayment"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <v-card>
      <v-card-title class="px-2">
        {{ $t('channel_pay') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewPayment = true"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          {{ $t('add_payment_channels') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          icon
          @click="isAddNewPayment = true"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model="shop_bank_check"
            :label="$t('status')"
            :items="statusList"
            :item-text="$i18n.locale"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableColumns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.shop_bank_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.shop_bank_default`]="{ item }">
          <span v-if="item.shop_bank_default == '0'">
            {{ $t("cash") }}
          </span>
          <span v-if="item.shop_bank_default == '1'">
            {{ $t("bank") }}
          </span>
          <span v-if="item.shop_bank_default == '2'">
            {{ $t("credit") }}
          </span>
          <span v-if="item.shop_bank_default == '5'">
            {{ $t("application_payment") }}
          </span>
          <span v-if="item.shop_bank_default == '6'">
            {{ $t("other") }}
          </span>
        </template>
        <template v-slot:[`item.shop_bank_check`]="{ item }">
          <StatusBlock :status="item.shop_bank_check" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                :disabled="item.shop_bank_default == 3 || item.shop_bank_default == 4 || item.shop_bank_name =='paysolutions'"
                v-bind="attrs"
                @click="isEditPayment = true; dataEdit = item"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.shop_bank_default == 3 || item.shop_bank_default == 4 || item.shop_bank_name =='paysolutions'"
                @click="shop_bank_check_update = item.shop_bank_check; shop_bank_id = item.shop_bank_id;isStatusUpdate = true"
                v-on="on"
              >
                <v-icon>{{ item.shop_bank_check==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.shop_bank_check==1?$t("delete"):$t("normal") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="isStatusUpdate"
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>  {{ shop_bank_check_update==1?$t("confirm_paymnent_channel_suspend"):$t("cancel_paymnent_channel_suspend") }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processLoading"
            :disabled="processLoading"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isStatusUpdate = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiAlertOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import usePaymentChannel from './usePaymentChannel'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewPayment from './AddNewPayment.vue'
import EditPayment from './EditPayment.vue'

export default {
  components: {
    StatusBlock,
    AddNewPayment,
    EditPayment,
  },
  setup() {
    const isAddNewPayment = ref(false)
    const isEditPayment = ref(false)
    const dataEdit = ref({})
    const {
      processLoading,
      isStatusUpdate,
      shop_bank_id,
      shop_bank_check_update,
      searchtext,
      shop_bank_check,
      statusList,
      tableColumns,
      dataTableList,
      loading,
      options,
      fetchDataTable,
      updateStatus,
    } = usePaymentChannel()

    return {
      processLoading,
      isStatusUpdate,
      shop_bank_id,
      shop_bank_check_update,
      fetchDataTable,
      updateStatus,
      isEditPayment,
      dataEdit,
      isAddNewPayment,
      searchtext,
      shop_bank_check,
      statusList,
      tableColumns,
      dataTableList,
      loading,
      options,
      icons: {
        mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiAlertOutline,
      },
    }
  },

}
</script>
