import { onMounted, ref, watch } from '@vue/composition-api'
import basicStatus from '@/@fake-db/data/shop_status.json'
import paymentChannel from '@/api/systemSetting/paymentChannel'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default function usePaymentChannel() {
  const searchtext = ref('')
  const statusList = ref(basicStatus.data)
  const shop_bank_check = ref('1')
  const tableColumns = [
    { text: '#', align: 'start', value: 'shop_bank_id' },
    { text: i18n.t('account_alias'), value: 'shop_bank_name' },
    { text: i18n.t('type'), value: 'shop_bank_default' },
    { text: i18n.t('status'), value: 'shop_bank_check' },
    {
      text: i18n.t('option'), value: 'actions', align: 'center',
    },
  ]
  const dataTableList = ref([])
  const loading = ref(false)
  const options = ref({})

  // update status valiable
  const isStatusUpdate = ref(false)
  const shop_bank_id = ref('')
  const shop_bank_check_update = ref('')
  const processLoading = ref(false)

  const { paymentChannelList, paymentUpdateStatus } = paymentChannel
  onMounted(() => {
    fetchDataTable()
  })
  const fetchDataTable = () => {
    loading.value = true
    const body = {
      searchtext: searchtext.value,
      shop_bank_check: shop_bank_check.value,
    }
    paymentChannelList(body).then(res => {
      const { data } = res
      dataTableList.value = data
      options.value.itemsPerPage = -1
      options.value.page = 1
      loading.value = false
    })
  }

  const updateStatus = () => {
    processLoading.value = true
    paymentUpdateStatus({
      shop_bank_id: shop_bank_id.value,
      shop_bank_check: shop_bank_check_update.value == '0' ? '1' : '0',
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      processLoading.value = false
      fetchDataTable()
      isStatusUpdate.value = false
    })
  }

  watch([searchtext, shop_bank_check], (newvalue, oldvalue) => {
    if (newvalue !== oldvalue) {
      fetchDataTable()
    }
  })

  return {
    isStatusUpdate,
    shop_bank_id,
    shop_bank_check_update,
    searchtext,
    shop_bank_check,
    statusList,
    tableColumns,
    dataTableList,
    loading,
    options,
    processLoading,
    fetchDataTable,
    updateStatus,
  }
}
