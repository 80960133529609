<template>
  <div>
    <v-dialog
      v-model="isEditPayment"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t("edit_payment_channels") }}
        </v-card-title>
        <v-form
          ref="formEditPayment"
          @submit.prevent="updatePayment"
        >
          <v-card-text>
            <v-select
              v-model="dataEditLocal.shop_bank_default"
              outlined
              :label="$t('type')"
              dense
              :items="typeList"
              :item-text="$i18n.locale"
              item-value="value"
            ></v-select>
            <v-text-field
              v-model="dataEditLocal.shop_bank_name"
              outlined
              autofocus
              dense
              :rules="[required]"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-payment', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import chanel_pay_type from '@/@fake-db/data/chanel_pay_type.json'
import { required } from '@/@core/utils/validation'
import paymentChannel from '@/api/systemSetting/paymentChannel'

export default {
  model: {
    prop: 'isEditPayment',
    event: 'update:is-edit-payment',
  },
  props: {
    isEditPayment: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const dataEditLocal = ref({})
    const formEditPayment = ref(null)
    const typeList = ref(JSON.parse(JSON.stringify(chanel_pay_type.data)))
    const loading = ref(false)

    const { paymentUpdate } = paymentChannel

    const updatePayment = () => {
      loading.value = true
      const isFormValid = formEditPayment.value.validate()
      if (!isFormValid) return
      paymentUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        formEditPayment.value.reset()
        emit('update:is-edit-payment', false)
        emit('onUpdate', res.response)
      })
    }
    watch(() => props.dataEdit, newVal => {
      if (newVal) {
        dataEditLocal.value = JSON.parse(JSON.stringify(newVal))
        dataEditLocal.value.shop_bank_default = parseInt(newVal.shop_bank_default)
      }
    })

    return {
      typeList,
      dataEditLocal,
      formEditPayment,
      loading,
      updatePayment,
      required,
    }
  },
}
</script>
