<template>
  <div>
    <v-dialog
      v-model="isAddNewPayment"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t("add_payment_channels") }}
        </v-card-title>
        <v-form
          ref="formAddPayment"
          @submit.prevent="createNewPayment"
        >
          <v-card-text>
            <v-select
              v-model="shop_bank_default"
              outlined
              :label="$t('type')"
              dense
              :items="typeList"
              :item-text="$i18n.locale"
              item-value="value"
            ></v-select>
            <v-text-field
              v-model="shop_bank_name"
              outlined
              autofocus
              dense
              :rules="[required]"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-payment', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import chanel_pay_type from '@/@fake-db/data/chanel_pay_type.json'
import { required } from '@/@core/utils/validation'
import paymentChannel from '@/api/systemSetting/paymentChannel'

export default {
  model: {
    prop: 'isAddNewPayment',
    event: 'update:is-add-new-payment',
  },
  props: {
    isAddNewPayment: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const formAddPayment = ref(null)
    const typeList = ref(JSON.parse(JSON.stringify(chanel_pay_type.data)))
    const shop_bank_default = ref(0)
    const shop_bank_name = ref('')
    const loading = ref(false)

    const { paymentAdd } = paymentChannel

    const createNewPayment = () => {
      loading.value = true
      const isFormValid = formAddPayment.value.validate()
      if (!isFormValid) return
      paymentAdd({
        shop_bank_name: shop_bank_name.value,
        shop_bank_default: shop_bank_default.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        shop_bank_name.value = ''
        shop_bank_default.value = 0
        emit('update:is-add-new-payment', false)
        emit('onAdd', res.response)
      })
    }
    watch(() => props.isAddNewPayment, newValue => {
      if (newValue) {
        shop_bank_name.value = ''
        shop_bank_default.value = 0
      }
    })

    return {
      typeList,
      shop_bank_default,
      shop_bank_name,
      formAddPayment,
      loading,
      createNewPayment,
      required,
    }
  },
}
</script>
